@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,500,0,0');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: 'Satoshi-Variable';
  src:
    url('../../public/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Variable.woff') format('woff'),
    url('../../public/fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-VariableItalic';
  src:
    url('../../public/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-VariableItalic.woff') format('woff'),
    url('../../public/fonts/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi-Light';
  src:
    url('../../public/fonts/Satoshi-Light.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Light.woff') format('woff'),
    url('../../public/fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-LightItalic';
  src:
    url('../../public/fonts/Satoshi-LightItalic.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-LightItalic.woff') format('woff'),
    url('../../public/fonts/Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi-Regular';
  src:
    url('../../public/fonts/Satoshi-Regular.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Regular.woff') format('woff'),
    url('../../public/fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Italic';
  src:
    url('../../public/fonts/Satoshi-Italic.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Italic.woff') format('woff'),
    url('../../public/fonts/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi-Medium';
  src:
    url('../../public/fonts/Satoshi-Medium.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Medium.woff') format('woff'),
    url('../../public/fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-MediumItalic';
  src:
    url('../../public/fonts/Satoshi-MediumItalic.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-MediumItalic.woff') format('woff'),
    url('../../public/fonts/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi-Bold';
  src:
    url('../../public/fonts/Satoshi-Bold.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Bold.woff') format('woff'),
    url('../../public/fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-BoldItalic';
  src:
    url('../../public/fonts/Satoshi-BoldItalic.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-BoldItalic.woff') format('woff'),
    url('../../public/fonts/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi-Black';
  src:
    url('../../public/fonts/Satoshi-Black.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Black.woff') format('woff'),
    url('../../public/fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-BlackItalic';
  src:
    url('../../public/fonts/Satoshi-BlackItalic.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-BlackItalic.woff') format('woff'),
    url('../../public/fonts/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
